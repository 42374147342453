/* -------------------------------- Primary style -------------------------------- */
@import "./swiper.css";
*,
*::after,
*::before {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
}
/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: "MADE Mirage Regular";
  font-style: normal;
  font-weight: normal;
  src: local("MADE Mirage Regular"),
    url("MADE_MIrage_Regular.woff") format("woff");
}

@font-face {
  font-family: "MADE Mirage Thin";
  font-style: normal;
  font-weight: normal;
  src: local("MADE Mirage Thin"), url("MADE_MIrage_Thin.woff") format("woff");
}

body {
  overflow-x: hidden;
  background-image: linear-gradient(
    to top,
    #faf5e8 0%,
    #ffffff 13%,
    #ffffff 25%,
    #faf5e8 38%,
    #ffffff 50%,
    #faf5e8 62%,
    #faf5e8 75%,
    #ffffff 87%,
    #ffffff 100%
  );
  overscroll-behavior-y: none;
}
body.test {
  background-image: none;
}
:is(.footer, .spory) {
  /* padding-left: 50px;
  padding-right: 50px; */
}
main {
  max-width: 1900px;
  padding-left: 50px;
  padding-right: 50px;
  margin: 0 auto;
}
@media (max-width: 800px) {
  :is(.spory, .footer) {
    padding-left: 0px;
    padding-right: 0px;
  }
  :is(main) {
    padding-left: 00px;
    padding-right: 0px;
  }
}
.langSwitch {
  display: block;
  position: relative;

  text-align: center;
}
.langSwitch a {
  text-decoration: none;
  padding: 10px;
  display: inline-block;

  letter-spacing: 0px;
  color: #323549;

  font-weight: 500;
  opacity: 1;
}

.langSwitch.menu a {
  font-family: Playfair Display, serif;
  font-size: 20px;
  font-weight: 500;
}
@media (max-width: 800px) {
  .langSwitch.menu a {
    font-family: "Montserrat", sans-serif;
    font-size: 10px !important;
    font-weight: 600;
    padding-left: 0;
    padding-right: 0;
    text-transform: uppercase;
  }
}

.langSwitch a:hover {
  opacity: 1;
}
@media (min-width: 1100px) {
  .langSwitch a.selected::after {
    display: block;
    content: "";
    height: 1px;
    width: 100%;
    margin-top: 5px;
    background-color: #323549;
  }
  .langSwitch a::after {
    display: block;
    content: "";
    height: 1px;
    width: 0px;
    margin-top: 5px;
    -webkit-transition: width 0.4s ease;
    -moz-transition: width 0.4s ease;
    -o-transition: width 0.4s ease;
    -ms-transition: width 0.4s ease;
    transition: width 0.4s ease;
    background-color: #323549;
  }
  .langSwitch a:hover::after {
    width: 100%;
    -webkit-transition: width 0.4s ease;
    -moz-transition: width 0.4s ease;
    -o-transition: width 0.4s ease;
    -ms-transition: width 0.4s ease;
    transition: width 0.4s ease;
  }
}
.hideDesktop {
  display: none;
}

.anim svg .fadeInLine00 {
  height: 3.5px;
  animation: lineOnJedna 2s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-animation: lineOnJedna 2s cubic-bezier(0.165, 0.84, 0.44, 1);
}
@-webkit-keyframes lineOnJedna {
  0% {
    fill-opacity: 0;
    width: 0px;
  }
  80% {
  }
  100% {
    fill-opacity: 1;
    width: 645px;
  }
}
@keyframes lineOnJedna {
  0% {
    fill-opacity: 0;
    width: 0px;
  }
  50% {
  }
  100% {
    fill-opacity: 1;
    width: 645px;
  }
}
.homeslide.loaded {
  transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-property: all;
  transition-duration: 1.8s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-delay: 0.5s;
}
.anim svg .fadeInLine02 {
  animation: lineOnDva 2s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-animation: lineOnDva 2s cubic-bezier(0.165, 0.84, 0.44, 1);
}
@-webkit-keyframes lineOnDva {
  0% {
    fill-opacity: 0;
    width: 0px;
  }
  80% {
  }
  100% {
    fill-opacity: 1;
    width: 232px;
  }
}
@keyframes lineOnDva {
  0% {
    fill-opacity: 0;
    width: 0px;
  }
  50% {
  }
  100% {
    fill-opacity: 1;
    width: 232px;
  }
}
.fadeInLogo {
  opacity: 0;
}
.logoJP {
  fill: #323549;
}
.anim svg .fadeInLogo {
  opacity: 1;
  transition: all 3.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-property: all;
  transition-duration: 3.6s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-delay: 0s;
}
.anim svg .fadeInLine01 {
  animation: lineOnTri 2s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-animation: lineOnTri 2s cubic-bezier(0.165, 0.84, 0.44, 1);
}
@-webkit-keyframes lineOnTri {
  0% {
    fill-opacity: 0;
    width: 0px;
  }
  80% {
  }
  100% {
    fill-opacity: 1;
    width: 886px;
  }
}
@keyframes lineOnTri {
  0% {
    fill-opacity: 0;
    width: 0px;
  }
  50% {
  }
  100% {
    fill-opacity: 1;
    width: 886px;
  }
}
.jpFooter {
  padding-top: 50px;
  text-align: center;
}
.jpFooter svg {
  width: 350px;
}
.jpFooter img {
  width: 80px;
}

.footerHolder {
  padding: 20px;
}
.nameHolder {
  padding-top: 50px;
  padding-left: 50px;
}
.left,
.middle,
.right {
  width: 100%;
}
.right {
  text-align: right;
}
.middle {
  text-align: center;
}
a.odkaz {
  display: inline-block;
  text-decoration: none;
  border-radius: 50vw;
  text-transform: uppercase;
  padding: 10px;
  padding-left: 25px;
  padding-right: 25px;
  color: #323549;
  border: 1px solid #323549;
  font-weight: 600 !important;
  font-family: "Montserrat", sans-serif;
}
.bio {
  display: block;
  width: 300px;
  padding-top: 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  font-size: 12px;
  line-height: 20px;
  padding-left: 0px;
}
.bio strong {
  font-weight: 300;
}
.titul {
  display: inline;
  padding-left: 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 3px;
  padding-left: 3px;
}
.jmeno {
  display: block;
  padding-left: 120px;
  margin-top: -20px;
}
.prijmeni {
  display: block;
  margin-top: -20px;
}
.jmeno,
.prijmeni {
  font-family: "Playfair Display", serif;
  font-size: 85px;
  color: #323549;
  font-weight: 700;
}
.lineFooter {
  margin-top: 30px;
  height: 0px;
  width: 100%;
  background-color: #323549;
}
.line1 {
  font-family: "Playfair Display", serif;
  color: #323549;
  display: block;
  font-size: 20px;
}
.line1 strong {
  font-weight: 600;
  font-size: 27px;
}
.line2 {
  font-family: "Montserrat", sans-serif;
  color: #323549;
  font-weight: 200;
  font-size: 13px;
  display: block;
}
.line3 {
  font-family: "Montserrat", sans-serif;
  color: #323549;
  font-weight: 500;
  font-size: 16px;

  display: block;
}
.line4 {
  font-family: "Montserrat", sans-serif;
  color: #323549;
  font-weight: 500;
  font-size: 16px;
  display: block;
}
.homeslide {
  height: 100vh;
  left: 0;
  width: 100%;
}

nav {
  display: block;
  position: fixed;
  top: 0;

  left: 0;
  width: 100%;

  z-index: 99999;

  transition: transform 0.8s ease-in-out;
}

nav.hidden {
  transform: translateY(-100%);
}

.navWrap {
  max-width: 1900px;

  padding: 25px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: row;
  border-radius: 20px;
  justify-content: space-between;
  align-items: flex-end;
  /* backdrop-filter: blur(8px); */
}
#map {
  height: 50vh;
  overflow: hidden;
  border-radius: 40px 40px 0 0;
  mix-blend-mode: multiply;

  outline: 0;
}

#map * {
  outline: 0;
}
.shadow {
  top: 0;
  position: absolute;
  margin: 0 auto;
  display: block;
  left: 0;
  width: 100%;
  z-index: -1;
  max-width: 1900px;

  bottom: 0;

  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
  border-radius: 20px;
  mix-blend-mode: darken;
}
.navWrap .langSwitch {
  display: flex;
  width: auto;
  column-gap: 20px;
  flex-direction: row;
  justify-content: space-between;
}

@media (max-width: 800px) {
  .navWrap .langSwitch {
    width: auto;
    padding: 0px;
  }

  /* .navWrap .langSwitch:nth-of-type(2) {
    position: absolute;
    top: 50vh;
    justify-content: center;
  } */

  [hideMobile] {
    display: none;
  }

  [hideDesktop] {
    display: block;
  }
}
.homeslide.loaded {
  padding-top: 70px;
  padding-bottom: 70px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.homeSlide {
  padding: 0;
}
@media (max-width: 800px) {
  .homeslide {
    width: 100vw;
  }
  .homeIntro {
    width: 100vw !important;
  }
}
.footer {
  padding-top: 70px;
}
.spory h2 {
  padding-bottom: 50px;

  text-align: center;
}
.sporyInner {
}

.spory {
  margin-top: 60px;
}
.sporyInner {
  width: 100%;
  height: auto;
  border-radius: 40px;
  margin-top: 60px;

  padding: 100px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0px 9px 30px rgba(249, 221, 181, 0.3);
  box-shadow: rgba(238, 206, 195, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}
@media (max-width: 1200px) {
  .sporyInner {
    padding: 3px;
  }
}
.innerHolder {
  width: 100%;
  height: 100%;
  position: relative;
}
.pravniSdeleni {
  text-align: right;
}
.col33.line {
  padding-left: 0px;
  padding-right: 30px;
}
.linka {
  height: 2px;
  width: 100%;
}
.pravniSdeleniP {
  font-family: "Montserrat", sans-serif;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding-bottom: 40px;
}
.pravniSdeleniP a {
  font-weight: 600;
}
.innnerHolder {
  width: 100%;
  height: 100%;
}
.rowFlex {
  display: flex;
  padding: 50px;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 40px;
  align-content: stretch;
}

.rowFlex > *:nth-of-type(1) {
  flex-basis: 33%;
}

.rowFlex > *:nth-of-type(2) {
  position: relative;
  padding-left: 40px;
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
}
.rowFlex > *:nth-of-type(2):before {
  display: block;
  content: "";
  top: 0;
  width: 1px;
  bottom: 0;
  position: absolute;
  left: 0;
  background-color: rgb(154, 127, 91);
}
@media (max-width: 800px) {
  .rowFlex > *:nth-of-type(1) h2 {
    width: fit-content;
  }
  .rowFlex {
    display: flex;
    padding: 20px;
    flex-direction: column;
  }
  .rowFlex > *:nth-of-type(2) {
    position: relative;
    padding-left: 0px;
    flex-basis: 1;
  }
  .rowFlex > *:nth-of-type(2):before {
    display: none;
  }
  .sporyInner {
    padding-bottom: 0px;
    padding-top: 0px;
  }
}
.headlineHolder {
  display: flex;
  padding-top: 50px;
  align-items: center;
  padding-bottom: 50px;
  flex-direction: row;
  justify-content: center;
}
h2.servicesH2 {
  font-family: "Playfair Display", serif;
  font-size: 65px;
  color: #323549;
  font-weight: 700;
  display: block;
  line-height: 1.1;
  float: left;
}
@media (max-width: 800px) {
  .headlineHolder {
    display: flex;
    padding-top: 25px;
    padding-bottom: 5px;
    flex-direction: column;
    justify-content: center;
  }
}
h3 {
  font-family: "Playfair Display", serif;
  font-size: 30px;
  font-weight: 700;
  padding-right: 15%;
  line-height: 1;
}
.row {
  padding-top: 80px;
}
.serviceItem {
  float: left;
  width: calc(33%);
  padding-left: 80px;
  padding-right: 30px;
  height: 280px;
  padding-bottom: 30px;
  margin-bottom: 10px;
}
.serviceItem::before {
  display: block;
  height: 2px;
  width: 50px;
  position: absolute;
  -webkit-transform: translateX(-80px) translateY(18px);
  -ms-transform: translateX(-80px) translateY(18px);
  transform: translateX(-80px) translateY(18px);
  content: "";
  background-color: #323549;
}
.serviceItem p {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;

  line-height: 22px;
  padding-top: 20px;
  font-weight: 400;
  color: #323549;
}
.cleared {
  clear: both;
}
.subHeadline {
  font-family: "Montserrat", sans-serif;
  color: #323549;
  letter-spacing: 5px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
  padding-left: 30px;
  padding-top: 58px;
  display: inline-block;
}
.services {
  width: 100%;
  height: auto;

  padding: 70px 20px 70px 20px;
}

.jp {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
  -webkit-box-shadow: -4px 3px 132px -7px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: -4px 3px 132px -7px rgba(0, 0, 0, 0.27);
  box-shadow: -4px 3px 132px -7px rgba(0, 0, 0, 0.27);
  background-image: url(../img/jp.jpg);
  background-size: cover;
  background-position: top right;
}
.homeIntro {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.homeIntro > div {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.logoHolder {
  width: 40vw;
  max-width: 700px;
  mix-blend-mode: darken;
}

.screen {
  width: 100vw;
  height: 100svh;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1);
  border-radius: 0 0 30px 30px;
  background-image: linear-gradient(15deg, #faf5e8 0%, #ffffff 100%);
  /* -webkit-box-shadow: 29px -15px 124px -9px rgba(97, 100, 115, 0.29);
  -moz-box-shadow: 29px -15px 124px -9px rgba(97, 100, 115, 0.29);
  box-shadow: 29px -15px 124px -9px rgba(97, 100, 115, 0.29); */
}
@media (min-height: 970px) and (max-height: 5000px) {
  .services {
    height: auto;
    padding-bottom: 100px;
    padding-top: 100px;
  }
}
@media (min-width: 1600px) and (max-width: 5000px) {
  .homeslide.loaded {
  }
  .services {
    padding-bottom: 100px;
    padding-top: 100px;
  }
  .aboutSlide {
    padding-bottom: 100px;
    padding-top: 100px;
  }

  .spory {
    margin: 0 auto;
  }
}
@media (min-width: 320px) and (max-width: 1024px) {
  .homeslide {
    height: calc(100svh) !important;
  }
  .servicesH2 {
    display: block;
    text-align: center;
    width: 100%;
  }

  .homeslide.loaded {
    padding: 20px;
    transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition-property: all;
    transition-duration: 1.8s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    transition-delay: 0.5s;
  }
  .hideDesktop {
    display: block;
  }
  .jmeno,
  .prijmeni {
    font-family: "Playfair Display", serif;
    font-size: 45px;
    color: #323549;
    font-weight: 700;
  }
  .titul {
    padding-left: 0px;
    font-family: "Montserrat", sans-serif;

    font-size: 12px;
    font-weight: 500;
    padding-bottom: 10px;
    letter-spacing: 3px;
  }
  .jmeno {
    display: inline-block;
    padding-left: 65px;
    margin-top: -20px;
  }
  .prijmeni {
    display: block;
    margin-top: -15px;
  }
  .bio {
    display: block;
    width: 100%;
    padding-top: 20px;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    font-size: 11px;
    line-height: 20px;
    padding-left: 0px;
  }
  .nameHolder {
    padding-top: 50px;
    padding-right: 50px;
    padding-left: 50px;
    padding-bottom: 80px;
  }
  .bio {
    width: 100%;
  }
  .services {
    padding-top: 80px;
  }
  .services .innerContent {
    padding: 5px;
  }
  h2.servicesH2 {
    display: block;
    text-align: center;
  }
  .subHeadline {
    letter-spacing: 3px;
    text-align: center;
    font-size: 12px;
    padding-left: 0px;
    padding-top: 20px;
  }

  .serviceItem {
    float: none;
    height: auto;
    padding-bottom: 0px;
    margin-bottom: 40px;
    width: calc(100%);
  }
  .hideMobile {
    display: none;
  }
  .jpFooter svg {
    width: 250px;
  }
  .innnerHolder {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .col20 {
    height: 50px;
  }
  .col20,
  .col30,
  .col50 {
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
    float: none;
    position: relative;
    padding-bottom: 15px;
    height: auto;
  }
  .col50 {
    padding-left: 0px;
  }
  .col20 h3,
  .col33 p,
  .col50 p {
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
  }
  .pravniSdeleni {
    text-align: left !important;
    width: max-content;
    text-wrap: nowrap;
    display: block;
  }
  .pravniSdeleniP {
    padding-top: 20px !important;
  }
  .aboutSlide {
    height: 50vh;

    padding-bottom: 50px;
  }
  .spory {
    width: 100%;
  }
  .sporyInner {
    width: 100%;
    height: auto;
  }
  .columnInnerHolder {
    height: 100%;
    overflow: hidden;
    padding-left: 20px;
    padding-right: 20px;
  }

  canvas {
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .col20,
  .col30,
  .col50 {
    width: 100%;
  }
  .footer {
    width: 100%;
  }
  .left,
  .middle,
  .right {
    width: 100%;
    padding-bottom: 30px;
    text-align: center;
  }
  .logoHolder {
    width: 80%;
  }
}
.aboutSwiper {
  padding-top: 50px;
}
@media (min-width: 720px) and (max-width: 1199px) {
  .services,
  .aboutSwiper {
    padding: 50px;
  }
}
@media (min-width: 320px) and (max-width: 720px) {
  .aboutSlide {
    height: 320px !important;
    min-height: 380px;
  }
}
@media (min-width: 720px) and (max-width: 1000px) {
  .services {
    padding: 50px;
  }
  .serviceItem::before {
    width: 20px;
    -webkit-transform: translateX(-40px) translateY(18px);
    -ms-transform: translateX(-40px) translateY(18px);
    transform: translateX(-40px) translateY(18px);
  }
  .serviceItem {
    float: left;
    width: calc(50%);
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 40px;
  }
}
@media (min-width: 720px) and (max-width: 1024px) {
  .homeslide {
    padding: 0px;
  }
  .aboutSlide {
    min-height: 450px;
    height: 450px;
  }
  .homeslide.loaded {
    padding: 50px;
  }
}
.dotsAnim {
  width: 10px;
  margin: 0 auto;
}

.dotsAnim.jumpTop {
  transform: translateY(-60px);
}
@media (max-width: 800px) {
  .dotsAnim.jumpTop {
    padding-top: 20px;
    padding-bottom: 20px;
    transform: translateY(0px);
  }
}
.dot01 {
  opacity: 0.8;
}
.dot02 {
  animation: opat 5s ease-in-out infinite;
  -webkit-animation: opat 5s ease-in-out infinite;
}
.dot03 {
  animation: opat 3s ease-in-out infinite;
  -webkit-animation: opat 3s ease-in-out infinite;
}
.dot04 {
  animation: opat 2s ease-in-out infinite;
  -webkit-animation: opat 2s ease-in-out infinite;
}
@-webkit-keyframes opat {
  0% {
    fill-opacity: 0.2;
  }
  50% {
    fill-opacity: 0.8;
  }
  100% {
    fill-opacity: 0.2;
  }
}
@keyframes opat {
  0% {
    fill-opacity: 0.2;
  }
  50% {
    fill-opacity: 0.8;
  }
  100% {
    fill-opacity: 0.2;
  }
}
:is(h3, h2, h1) {
  color: rgb(50, 53, 73);
}
.hideDesktop {
  display: none !important;
}
.hideMobile {
  display: block !important;
}
@media (max-width: 800px) {
  .hideDesktop {
    display: block !important;
  }
  .hideMobile {
    display: none !important;
  }
}
body {
  max-width: 100vw;
  overflow-x: hidden;
  height: 100%;
  overscroll-behavior-y: none !important;
}

html {
  overscroll-behavior-y: none !important;
}
.annonce {
  margin-top: 15vh;
  margin-bottom: 15vh;
  border-top: 1px solid rgb(154, 127, 91);
  border-bottom: 1px solid rgb(154, 127, 91);
}

.annonce.bono {
  margin-top: 50px;
  margin-bottom: 50px;
  border: 0;
}

.annonce.bono .insideAnnonce {
  width: 80%;
  border-radius: 60px;
  margin: 0 auto;

  padding: 50px;
  display: flex;
  flex-direction: column;
  row-gap: 50px;
  text-align: center;
  align-items: center;
}

.insideAnnonce {
  width: 80%;
  border-radius: 60px;
  margin: 0 auto;

  padding: 50px;
  display: flex;
  flex-direction: column;
  row-gap: 50px;
  text-align: center;
  align-items: center;
}
.insideAnnonce h2 {
  font-size: 55px;

  line-height: 1;
  font-weight: 700;
}
.insideAnnonce p {
  line-height: 1.5;
}

* {
  text-wrap: balance;
}

.spacer15 {
  height: 15vh;
}
.insideAnnonce .subHeadline {
  font-family: "Montserrat", sans-serif;
  color: #323549;
  letter-spacing: 5px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
  text-align: center;
  padding-left: 0;
  padding-top: 20px;
  display: inline-block;
  font-weight: 700;
}
.occup {
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #323549;
}
a.line3 {
  text-decoration: none !important;
  color: #323549;
}
@media (max-width: 800px) {
  .insideAnnonce {
    width: 100%;
  }
  .insideAnnonce h2 {
    font-size: 45px;
    font-weight: 400;
    font-weight: 700;
  }
  .insideAnnonce .subHeadline {
    line-height: 1.5;
  }
  .spacer15 {
    height: 5vh;
  }
  main {
    max-width: 100vw;
    overflow-x: hidden;
  }
  .occup {
    font-size: 14px !important;
    line-height: 20px !important;
  }
  .serviceItem p {
    font-size: 14px !important;
  }
}
.slideText {
}

.sipka {
  height: 80px;
}

.playfaair {
  color: #323549;
  display: block;
  font-weight: 600;
  padding-bottom: 10px;
}

.blue {
  color: #323549;
}
.swiper-slide .who {
  font-family: "Playfair Display", serif !important;

  font-size: 16px;
  padding-top: 15px;
  padding-bottom: 20px;

  color: #323549;
}

.content {
}

.content__text {
  hyphens: auto;
  /* 
  font-family: "Playfair Display", serif !important; */
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;

  font-style: normal;
  font-weight: 300;
  width: 100vw;
  letter-spacing: 0px;

  color: #323549;
}
@media (max-width: 800px) {
  .content__text {
    font-size: 14px;
  }
}
@media (max-width: 720px) {
  .content__text {
    font-size: 22px;
    font-size: 14px;
  }
}
.js .content__text {
  grid-area: content;
  opacity: 0;
  pointer-events: none;
}

.js .content__text--current {
  opacity: 1;
  pointer-events: auto;
}

.motto {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%); */

  /* font-family: "Montserrat", sans-serif !important; */

  /* mix-blend-mode: overlay; */
}
