html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.swiper-3d .swiper-slide-shadow, .swiper-3d .swiper-slide-shadow-bottom, .swiper-3d .swiper-slide-shadow-left, .swiper-3d .swiper-slide-shadow-right, .swiper-3d .swiper-slide-shadow-top {
  pointer-events: none;
  z-index: 10;
  border-radius: calc(padding / 1.7);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes swiper-preloader-spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active, .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  transform: scale(1);
}

.swiper-fade .swiper-slide-active, .swiper-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

:root {
  --swiper-theme-color: #007aff;
}

:host {
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
}

.swiper {
  z-index: 1;
  padding: 0;
  list-style: none;
  display: block;
  position: relative;
  overflow: hidden;
}

.swiper-vertical > .swiper-wrapper {
  flex-direction: column;
}

.swiper-wrapper {
  z-index: 1;
  transition-property: transform;
  transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.swiper-android .swiper-slide, .swiper-ios .swiper-slide, .swiper-wrapper {
  transform: translate3d(0, 0, 0);
}

.swiper-horizontal {
  touch-action: pan-y;
}

.swiper-vertical {
  touch-action: pan-x;
}

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  transition-property: transform;
  display: block;
  position: relative;
}

.swiper-slide-invisible-blank {
  visibility: hidden;
}

.swiper-autoheight, .swiper-autoheight .swiper-slide {
  height: auto;
}

.swiper-autoheight .swiper-wrapper {
  align-items: flex-start;
  transition-property: transform, height;
}

.swiper-backface-hidden .swiper-slide {
  backface-visibility: hidden;
  transform: translateZ(0);
}

.swiper-3d.swiper-css-mode .swiper-wrapper {
  perspective: 1200px;
}

.swiper-3d .swiper-wrapper {
  transform-style: preserve-3d;
}

.swiper-3d {
  perspective: 1200px;
}

.swiper-3d .swiper-cube-shadow, .swiper-3d .swiper-slide {
  transform-style: preserve-3d;
}

.swiper-css-mode > .swiper-wrapper {
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: auto;
}

.swiper-css-mode > .swiper-wrapper::-webkit-scrollbar {
  display: none;
}

.swiper-css-mode > .swiper-wrapper > .swiper-slide {
  scroll-snap-align: start start;
}

.swiper-css-mode.swiper-horizontal > .swiper-wrapper {
  scroll-snap-type: x mandatory;
}

.swiper-css-mode.swiper-vertical > .swiper-wrapper {
  scroll-snap-type: y mandatory;
}

.swiper-css-mode.swiper-free-mode > .swiper-wrapper {
  scroll-snap-type: none;
}

.swiper-css-mode.swiper-free-mode > .swiper-wrapper > .swiper-slide {
  scroll-snap-align: none;
}

.swiper-css-mode.swiper-centered > .swiper-wrapper:before {
  content: "";
  flex-shrink: 0;
  order: 9999;
}

.swiper-css-mode.swiper-centered > .swiper-wrapper > .swiper-slide {
  scroll-snap-align: center center;
  scroll-snap-stop: always;
}

.swiper-css-mode.swiper-centered.swiper-horizontal > .swiper-wrapper > .swiper-slide:first-child {
  margin-inline-start: var(--swiper-centered-offset-before);
}

.swiper-css-mode.swiper-centered.swiper-horizontal > .swiper-wrapper:before {
  width: var(--swiper-centered-offset-after);
  height: 100%;
  min-height: 1px;
}

.swiper-css-mode.swiper-centered.swiper-vertical > .swiper-wrapper > .swiper-slide:first-child {
  margin-block-start: var(--swiper-centered-offset-before);
}

.swiper-css-mode.swiper-centered.swiper-vertical > .swiper-wrapper:before {
  height: var(--swiper-centered-offset-after);
  width: 100%;
  min-width: 1px;
}

.swiper-3d .swiper-slide-shadow {
  background: #000000e6;
}

.swiper-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, #00000080, #0000);
}

.swiper-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to right, #00000080, #0000);
}

.swiper-3d .swiper-slide-shadow-top {
  background-image: linear-gradient(to top, #00000080, #0000);
}

.swiper-3d .swiper-slide-shadow-bottom {
  background-image: linear-gradient(#00000080, #0000);
}

.swiper-lazy-preloader {
  z-index: 10;
  transform-origin: 50%;
  box-sizing: border-box;
  border: 4px solid var(--swiper-preloader-color, var(--swiper-theme-color));
  border-top-color: #0000;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  margin-top: -21px;
  margin-left: -21px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.swiper-watch-progress .swiper-slide-visible .swiper-lazy-preloader, .swiper:not(.swiper-watch-progress) .swiper-lazy-preloader {
  animation: 1s linear infinite swiper-preloader-spin;
}

.swiper-lazy-preloader-white {
  --swiper-preloader-color: #fff;
}

.swiper-lazy-preloader-black {
  --swiper-preloader-color: #000;
}

.swiper-virtual .swiper-slide {
  -webkit-backface-visibility: hidden;
  transform: translateZ(0);
}

.swiper-virtual.swiper-css-mode .swiper-wrapper:after {
  content: "";
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.swiper-virtual.swiper-css-mode.swiper-horizontal .swiper-wrapper:after {
  width: var(--swiper-virtual-size);
  height: 1px;
}

.swiper-virtual.swiper-css-mode.swiper-vertical .swiper-wrapper:after {
  height: var(--swiper-virtual-size);
  width: 1px;
}

:root {
  --swiper-navigation-size: 44px;
}

.swiper-pagination {
  text-align: center;
  z-index: 10;
  transition: opacity .3s;
  position: absolute;
  transform: translate3d(0, 0, 0);
}

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

.swiper-pagination-disabled > .swiper-pagination, .swiper-pagination.swiper-pagination-disabled {
  display: none !important;
}

.swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: var(--swiper-pagination-bottom, 8px);
  top: var(--swiper-pagination-top, auto);
  width: 100%;
  left: 0;
}

.swiper-pagination-bullets-dynamic {
  font-size: 0;
  overflow: hidden;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  position: relative;
  transform: scale(.33);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active, .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  transform: scale(1);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  transform: scale(.66);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  transform: scale(.33);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  transform: scale(.66);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  transform: scale(.33);
}

.swiper-pagination-bullet {
  width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px));
  height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px));
  border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
  background: var(--swiper-pagination-bullet-inactive-color, #000);
  opacity: var(--swiper-pagination-bullet-inactive-opacity, .2);
  display: inline-block;
}

button.swiper-pagination-bullet {
  box-shadow: none;
  appearance: none;
  border: none;
  margin: 0;
  padding: 0;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-bullet:only-child {
  display: none !important;
}

.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: var(--swiper-pagination-color, var(--swiper-theme-color));
}

.swiper-pagination-vertical.swiper-pagination-bullets, .swiper-vertical > .swiper-pagination-bullets {
  right: var(--swiper-pagination-right, 8px);
  left: var(--swiper-pagination-left, auto);
  top: 50%;
  transform: translate3d(0, -50%, 0);
}

.swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: var(--swiper-pagination-bullet-vertical-gap, 6px) 0;
  display: block;
}

.swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic, .swiper-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  width: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet, .swiper-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transition: transform .2s, top .2s;
  display: inline-block;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
}

.swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic, .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);
}

.swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transition: transform .2s, left .2s;
}

.swiper-horizontal.swiper-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transition: transform .2s, right .2s;
}

.swiper-pagination-fraction {
  color: var(--swiper-pagination-fraction-color, inherit);
}

.swiper-pagination-progressbar {
  background: var(--swiper-pagination-progressbar-bg-color, #00000040);
  position: absolute;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: var(--swiper-pagination-color, var(--swiper-theme-color));
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(0);
}

.swiper-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  transform-origin: 100% 0;
}

.swiper-horizontal > .swiper-pagination-progressbar, .swiper-pagination-progressbar.swiper-pagination-horizontal, .swiper-pagination-progressbar.swiper-pagination-vertical.swiper-pagination-progressbar-opposite, .swiper-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  height: var(--swiper-pagination-progressbar-size, 4px);
  width: 100%;
  top: 0;
  left: 0;
}

.swiper-horizontal > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite, .swiper-pagination-progressbar.swiper-pagination-horizontal.swiper-pagination-progressbar-opposite, .swiper-pagination-progressbar.swiper-pagination-vertical, .swiper-vertical > .swiper-pagination-progressbar {
  width: var(--swiper-pagination-progressbar-size, 4px);
  height: 100%;
  top: 0;
  left: 0;
}

.swiper-pagination-lock {
  display: none;
}

.swiper-scrollbar {
  border-radius: var(--swiper-scrollbar-border-radius, 10px);
  touch-action: none;
  background: var(--swiper-scrollbar-bg-color, #0000001a);
  position: relative;
}

.swiper-scrollbar-disabled > .swiper-scrollbar, .swiper-scrollbar.swiper-scrollbar-disabled {
  display: none !important;
}

.swiper-horizontal > .swiper-scrollbar, .swiper-scrollbar.swiper-scrollbar-horizontal {
  left: var(--swiper-scrollbar-sides-offset, 1%);
  bottom: var(--swiper-scrollbar-bottom, 4px);
  top: var(--swiper-scrollbar-top, auto);
  z-index: 50;
  height: var(--swiper-scrollbar-size, 4px);
  width: calc(100% - 2 * var(--swiper-scrollbar-sides-offset, 1%));
  position: absolute;
}

.swiper-scrollbar.swiper-scrollbar-vertical, .swiper-vertical > .swiper-scrollbar {
  left: var(--swiper-scrollbar-left, auto);
  right: var(--swiper-scrollbar-right, 4px);
  top: var(--swiper-scrollbar-sides-offset, 1%);
  z-index: 50;
  width: var(--swiper-scrollbar-size, 4px);
  height: calc(100% - 2 * var(--swiper-scrollbar-sides-offset, 1%));
  position: absolute;
}

.swiper-scrollbar-drag {
  background: var(--swiper-scrollbar-drag-bg-color, #00000080);
  border-radius: var(--swiper-scrollbar-border-radius, 10px);
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
}

.swiper-scrollbar-cursor-drag {
  cursor: move;
}

.swiper-scrollbar-lock {
  display: none;
}

.swiper-zoom-container {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.swiper-zoom-container > canvas, .swiper-zoom-container > img, .swiper-zoom-container > svg {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.swiper-slide-zoomed {
  cursor: move;
  touch-action: none;
}

.swiper .swiper-notification {
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
  position: absolute;
  top: 0;
  left: 0;
}

.swiper-free-mode > .swiper-wrapper {
  margin: 0 auto;
  transition-timing-function: ease-out;
}

.swiper-grid > .swiper-wrapper {
  flex-wrap: wrap;
}

.swiper-grid-column > .swiper-wrapper {
  flex-flow: column wrap;
}

.swiper-fade.swiper-free-mode .swiper-slide {
  transition-timing-function: ease-out;
}

.swiper-fade .swiper-slide {
  pointer-events: none;
  transition-property: opacity;
}

.swiper-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-fade .swiper-slide-active, .swiper-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-cube {
  overflow: visible;
}

.swiper-cube .swiper-slide {
  pointer-events: none;
  backface-visibility: hidden;
  z-index: 1;
  visibility: hidden;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}

.swiper-cube .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-cube.swiper-rtl .swiper-slide {
  transform-origin: 100% 0;
}

.swiper-cube .swiper-slide-active, .swiper-cube .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-cube .swiper-slide-active, .swiper-cube .swiper-slide-next, .swiper-cube .swiper-slide-prev {
  pointer-events: auto;
  visibility: visible;
}

.swiper-cube .swiper-cube-shadow {
  opacity: .6;
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.swiper-cube .swiper-cube-shadow:before {
  content: "";
  filter: blur(50px);
  background: #000;
  position: absolute;
  inset: 0;
}

.swiper-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible;
}

.swiper-cube .swiper-slide-shadow-cube.swiper-slide-shadow-bottom, .swiper-cube .swiper-slide-shadow-cube.swiper-slide-shadow-left, .swiper-cube .swiper-slide-shadow-cube.swiper-slide-shadow-right, .swiper-cube .swiper-slide-shadow-cube.swiper-slide-shadow-top {
  z-index: 0;
  backface-visibility: hidden;
}

.swiper-flip {
  overflow: visible;
}

.swiper-flip .swiper-slide {
  pointer-events: none;
  backface-visibility: hidden;
  z-index: 1;
}

.swiper-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-flip .swiper-slide-active, .swiper-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-flip .swiper-slide-shadow-flip.swiper-slide-shadow-bottom, .swiper-flip .swiper-slide-shadow-flip.swiper-slide-shadow-left, .swiper-flip .swiper-slide-shadow-flip.swiper-slide-shadow-right, .swiper-flip .swiper-slide-shadow-flip.swiper-slide-shadow-top {
  z-index: 0;
  backface-visibility: hidden;
}

.swiper-creative .swiper-slide {
  backface-visibility: hidden;
  transition-property: transform, opacity, height;
  overflow: hidden;
}

.swiper-cards {
  overflow: visible;
}

.swiper-cards .swiper-slide {
  transform-origin: bottom;
  backface-visibility: hidden;
  overflow: hidden;
}

:root {
  --swiper-theme-color: #007aff;
}

:host {
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
}

.swiper {
  z-index: 1;
  padding: 0;
  list-style: none;
  display: block;
  position: relative;
  overflow: hidden;
}

.swiper-vertical > .swiper-wrapper {
  flex-direction: column;
}

.swiper-wrapper {
  z-index: 1;
  transition-property: transform;
  transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.swiper-android .swiper-slide, .swiper-ios .swiper-slide, .swiper-wrapper {
  transform: translate3d(0, 0, 0);
}

.swiper-horizontal {
  touch-action: pan-y;
}

.swiper-vertical {
  touch-action: pan-x;
}

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  transition-property: transform;
  display: block;
  position: relative;
}

.swiper-slide-invisible-blank {
  visibility: hidden;
}

.swiper-autoheight, .swiper-autoheight .swiper-slide {
  height: auto;
}

.swiper-autoheight .swiper-wrapper {
  align-items: flex-start;
  transition-property: transform, height;
}

.swiper-backface-hidden .swiper-slide {
  backface-visibility: hidden;
  transform: translateZ(0);
}

.swiper-3d.swiper-css-mode .swiper-wrapper {
  perspective: 1200px;
}

.swiper-3d .swiper-wrapper {
  transform-style: preserve-3d;
}

.swiper-3d {
  perspective: 1200px;
}

.swiper-3d .swiper-cube-shadow, .swiper-3d .swiper-slide {
  transform-style: preserve-3d;
}

.swiper-css-mode > .swiper-wrapper {
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: auto;
}

.swiper-css-mode > .swiper-wrapper::-webkit-scrollbar {
  display: none;
}

.swiper-css-mode > .swiper-wrapper > .swiper-slide {
  scroll-snap-align: start start;
}

.swiper-css-mode.swiper-horizontal > .swiper-wrapper {
  scroll-snap-type: x mandatory;
}

.swiper-css-mode.swiper-vertical > .swiper-wrapper {
  scroll-snap-type: y mandatory;
}

.swiper-css-mode.swiper-free-mode > .swiper-wrapper {
  scroll-snap-type: none;
}

.swiper-css-mode.swiper-free-mode > .swiper-wrapper > .swiper-slide {
  scroll-snap-align: none;
}

.swiper-css-mode.swiper-centered > .swiper-wrapper:before {
  content: "";
  flex-shrink: 0;
  order: 9999;
}

.swiper-css-mode.swiper-centered > .swiper-wrapper > .swiper-slide {
  scroll-snap-align: center center;
  scroll-snap-stop: always;
}

.swiper-css-mode.swiper-centered.swiper-horizontal > .swiper-wrapper > .swiper-slide:first-child {
  margin-inline-start: var(--swiper-centered-offset-before);
}

.swiper-css-mode.swiper-centered.swiper-horizontal > .swiper-wrapper:before {
  width: var(--swiper-centered-offset-after);
  height: 100%;
  min-height: 1px;
}

.swiper-css-mode.swiper-centered.swiper-vertical > .swiper-wrapper > .swiper-slide:first-child {
  margin-block-start: var(--swiper-centered-offset-before);
}

.swiper-css-mode.swiper-centered.swiper-vertical > .swiper-wrapper:before {
  height: var(--swiper-centered-offset-after);
  width: 100%;
  min-width: 1px;
}

.swiper-3d .swiper-slide-shadow, .swiper-3d .swiper-slide-shadow-bottom, .swiper-3d .swiper-slide-shadow-left, .swiper-3d .swiper-slide-shadow-right, .swiper-3d .swiper-slide-shadow-top {
  pointer-events: none;
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.swiper-3d .swiper-slide-shadow {
  background: #000000e6;
}

.swiper-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, #00000080, #0000);
}

.swiper-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to right, #00000080, #0000);
}

.swiper-3d .swiper-slide-shadow-top {
  background-image: linear-gradient(to top, #00000080, #0000);
}

.swiper-3d .swiper-slide-shadow-bottom {
  background-image: linear-gradient(#00000080, #0000);
}

.swiper-lazy-preloader {
  z-index: 10;
  transform-origin: 50%;
  box-sizing: border-box;
  border: 4px solid var(--swiper-preloader-color, var(--swiper-theme-color));
  border-top-color: #0000;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  margin-top: -21px;
  margin-left: -21px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.swiper-watch-progress .swiper-slide-visible .swiper-lazy-preloader, .swiper:not(.swiper-watch-progress) .swiper-lazy-preloader {
  animation: 1s linear infinite swiper-preloader-spin;
}

.swiper-lazy-preloader-white {
  --swiper-preloader-color: #fff;
}

.swiper-lazy-preloader-black {
  --swiper-preloader-color: #000;
}

@keyframes swiper-preloader-spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.swiper-virtual .swiper-slide {
  -webkit-backface-visibility: hidden;
  transform: translateZ(0);
}

.swiper-virtual.swiper-css-mode .swiper-wrapper:after {
  content: "";
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.swiper-virtual.swiper-css-mode.swiper-horizontal .swiper-wrapper:after {
  width: var(--swiper-virtual-size);
  height: 1px;
}

.swiper-virtual.swiper-css-mode.swiper-vertical .swiper-wrapper:after {
  height: var(--swiper-virtual-size);
  width: 1px;
}

:root {
  --swiper-navigation-size: 44px;
}

.swiper-pagination {
  text-align: center;
  z-index: 10;
  transition: opacity .3s;
  position: absolute;
  transform: translate3d(0, 0, 0);
}

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

.swiper-pagination-disabled > .swiper-pagination, .swiper-pagination.swiper-pagination-disabled {
  display: none !important;
}

.swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: var(--swiper-pagination-bottom, 8px);
  top: var(--swiper-pagination-top, auto);
  width: 100%;
  left: 0;
}

.swiper-pagination-bullets-dynamic {
  font-size: 0;
  overflow: hidden;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  position: relative;
  transform: scale(.33);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active, .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  transform: scale(1);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  transform: scale(.66);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  transform: scale(.33);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  transform: scale(.66);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  transform: scale(.33);
}

.swiper-pagination-bullet {
  width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px));
  height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px));
  border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
  background: var(--swiper-pagination-bullet-inactive-color, #000);
  opacity: var(--swiper-pagination-bullet-inactive-opacity, .2);
  display: inline-block;
}

button.swiper-pagination-bullet {
  box-shadow: none;
  appearance: none;
  border: none;
  margin: 0;
  padding: 0;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-bullet:only-child {
  display: none !important;
}

.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: var(--swiper-pagination-color, var(--swiper-theme-color));
}

.swiper-pagination-vertical.swiper-pagination-bullets, .swiper-vertical > .swiper-pagination-bullets {
  right: var(--swiper-pagination-right, 8px);
  left: var(--swiper-pagination-left, auto);
  top: 50%;
  transform: translate3d(0, -50%, 0);
}

.swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: var(--swiper-pagination-bullet-vertical-gap, 6px) 0;
  display: block;
}

.swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic, .swiper-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  width: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet, .swiper-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transition: transform .2s, top .2s;
  display: inline-block;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
}

.swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic, .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);
}

.swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transition: transform .2s, left .2s;
}

.swiper-horizontal.swiper-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transition: transform .2s, right .2s;
}

.swiper-pagination-fraction {
  color: var(--swiper-pagination-fraction-color, inherit);
}

.swiper-pagination-progressbar {
  background: var(--swiper-pagination-progressbar-bg-color, #00000040);
  position: absolute;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: var(--swiper-pagination-color, var(--swiper-theme-color));
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(0);
}

.swiper-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  transform-origin: 100% 0;
}

.swiper-horizontal > .swiper-pagination-progressbar, .swiper-pagination-progressbar.swiper-pagination-horizontal, .swiper-pagination-progressbar.swiper-pagination-vertical.swiper-pagination-progressbar-opposite, .swiper-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  height: var(--swiper-pagination-progressbar-size, 4px);
  width: 100%;
  top: 0;
  left: 0;
}

.swiper-horizontal > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite, .swiper-pagination-progressbar.swiper-pagination-horizontal.swiper-pagination-progressbar-opposite, .swiper-pagination-progressbar.swiper-pagination-vertical, .swiper-vertical > .swiper-pagination-progressbar {
  width: var(--swiper-pagination-progressbar-size, 4px);
  height: 100%;
  top: 0;
  left: 0;
}

.swiper-pagination-lock {
  display: none;
}

.swiper-scrollbar {
  border-radius: var(--swiper-scrollbar-border-radius, 10px);
  touch-action: none;
  background: var(--swiper-scrollbar-bg-color, #0000001a);
  position: relative;
}

.swiper-scrollbar-disabled > .swiper-scrollbar, .swiper-scrollbar.swiper-scrollbar-disabled {
  display: none !important;
}

.swiper-horizontal > .swiper-scrollbar, .swiper-scrollbar.swiper-scrollbar-horizontal {
  left: var(--swiper-scrollbar-sides-offset, 1%);
  bottom: var(--swiper-scrollbar-bottom, 4px);
  top: var(--swiper-scrollbar-top, auto);
  z-index: 50;
  height: var(--swiper-scrollbar-size, 4px);
  width: calc(100% - 2 * var(--swiper-scrollbar-sides-offset, 1%));
  position: absolute;
}

.swiper-scrollbar.swiper-scrollbar-vertical, .swiper-vertical > .swiper-scrollbar {
  left: var(--swiper-scrollbar-left, auto);
  right: var(--swiper-scrollbar-right, 4px);
  top: var(--swiper-scrollbar-sides-offset, 1%);
  z-index: 50;
  width: var(--swiper-scrollbar-size, 4px);
  height: calc(100% - 2 * var(--swiper-scrollbar-sides-offset, 1%));
  position: absolute;
}

.swiper-scrollbar-drag {
  background: var(--swiper-scrollbar-drag-bg-color, #00000080);
  border-radius: var(--swiper-scrollbar-border-radius, 10px);
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
}

.swiper-scrollbar-cursor-drag {
  cursor: move;
}

.swiper-scrollbar-lock {
  display: none;
}

.swiper-zoom-container {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.swiper-zoom-container > canvas, .swiper-zoom-container > img, .swiper-zoom-container > svg {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.swiper-slide-zoomed {
  cursor: move;
  touch-action: none;
}

.swiper .swiper-notification {
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
  position: absolute;
  top: 0;
  left: 0;
}

.swiper-free-mode > .swiper-wrapper {
  margin: 0 auto;
  transition-timing-function: ease-out;
}

.swiper-grid > .swiper-wrapper {
  flex-wrap: wrap;
}

.swiper-grid-column > .swiper-wrapper {
  flex-flow: column wrap;
}

.swiper-fade.swiper-free-mode .swiper-slide {
  transition-timing-function: ease-out;
}

.swiper-fade .swiper-slide {
  pointer-events: none;
  transition-property: opacity;
}

.swiper-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-fade .swiper-slide-active, .swiper-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-cube {
  overflow: visible;
}

.swiper-cube .swiper-slide {
  pointer-events: none;
  backface-visibility: hidden;
  z-index: 1;
  visibility: hidden;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}

.swiper-cube .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-cube.swiper-rtl .swiper-slide {
  transform-origin: 100% 0;
}

.swiper-cube .swiper-slide-active, .swiper-cube .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-cube .swiper-slide-active, .swiper-cube .swiper-slide-next, .swiper-cube .swiper-slide-prev {
  pointer-events: auto;
  visibility: visible;
}

.swiper-cube .swiper-cube-shadow {
  opacity: .6;
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.swiper-cube .swiper-cube-shadow:before {
  content: "";
  filter: blur(50px);
  background: #000;
  position: absolute;
  inset: 0;
}

.swiper-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible;
}

.swiper-cube .swiper-slide-shadow-cube.swiper-slide-shadow-bottom, .swiper-cube .swiper-slide-shadow-cube.swiper-slide-shadow-left, .swiper-cube .swiper-slide-shadow-cube.swiper-slide-shadow-right, .swiper-cube .swiper-slide-shadow-cube.swiper-slide-shadow-top {
  z-index: 0;
  backface-visibility: hidden;
}

.swiper-flip {
  overflow: visible;
}

.swiper-flip .swiper-slide {
  pointer-events: none;
  backface-visibility: hidden;
  z-index: 1;
}

.swiper-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-flip .swiper-slide-active, .swiper-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-flip .swiper-slide-shadow-flip.swiper-slide-shadow-bottom, .swiper-flip .swiper-slide-shadow-flip.swiper-slide-shadow-left, .swiper-flip .swiper-slide-shadow-flip.swiper-slide-shadow-right, .swiper-flip .swiper-slide-shadow-flip.swiper-slide-shadow-top {
  z-index: 0;
  backface-visibility: hidden;
}

.swiper-creative .swiper-slide {
  backface-visibility: hidden;
  transition-property: transform, opacity, height;
  overflow: hidden;
}

.swiper-cards {
  overflow: visible;
}

.swiper-cards .swiper-slide {
  transform-origin: bottom;
  backface-visibility: hidden;
  overflow: hidden;
}

.swiper {
  padding: 4px 0 40px;
}

@media (width <= 800px) {
  .swiper {
    width: calc(100% + 50px);
    padding: 0 0 60px;
    transform: translateX(-25px);
  }

  .swiper1 {
    padding-top: 80px;
  }
}

@media (width >= 801px) {
  .swiper-wrapper, .swiper {
    overflow: visible;
  }
}

.swiper, .swiper-slide, .swiper-wrapper {
  height: auto;
}

.swiper-slide {
  background-image: linear-gradient(15deg, #faf5e8 0%, #fff 100%);
  border-radius: 0 60px 30px 30px;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  align-items: stretch;
  display: flex;
  box-shadow: 0 25px 50px -12px #00000040;
}

.swiper-slide > div:not(.slide-image) {
  padding: 25px;
}

@media (width <= 800px) {
  .swiper-slide {
    padding: 0;
  }

  .swiper-slide > div:not(.slide-image) {
    padding: 25px;
  }
}

.swiper-slide p {
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}

@media (width <= 800px) {
  .swiper-slide p {
    font-size: 12px;
  }
}

.swiper-slide :is(.name, .prijmeni) {
  color: #323549;
  text-indent: 0%;
  width: auto;
  font-family: Playfair Display, serif;
  font-size: 45px;
  font-weight: 700;
  line-height: 1;
}

@media (width <= 800px) {
  .swiper-slide :is(.name, .prijmeni) {
    font-size: 35px;
  }
}

.swiper-slide .titul {
  padding-top: 40px;
  padding-bottom: 10px;
  padding-left: 0;
}

.swiper-slide .line3:first-of-type {
  padding-top: 15px;
  text-decoration: underline;
}

.swiper-slide .line3:nth-of-type(2) {
  padding-top: 5px;
}

.cardRow {
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 60px;
  display: flex;
}

.cardRow.image {
  margin-top: 30px;
}

.cardRow.image2 {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.swiper-slide .cardRow:nth-of-type(2) {
  border-top: 1px dotted #323549;
  margin-top: 25px;
}

.cardRow * {
  text-align: right !important;
}

.cardRow > .bio {
  text-align: right;
  text-align: left;
}

*, :after, :before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
}

@font-face {
  font-family: MADE Mirage Regular;
  font-style: normal;
  font-weight: normal;
  src: local(MADE Mirage Regular), url("MADE_MIrage_Regular.37179cd3.woff") format("woff");
}

@font-face {
  font-family: MADE Mirage Thin;
  font-style: normal;
  font-weight: normal;
  src: local(MADE Mirage Thin), url("MADE_MIrage_Thin.b20743e9.woff") format("woff");
}

body {
  overscroll-behavior-y: none;
  background-image: linear-gradient(to top, #faf5e8 0%, #fff 13% 25%, #faf5e8 38%, #fff 50%, #faf5e8 62% 75%, #fff 87% 100%);
  overflow-x: hidden;
}

body.test {
  background-image: none;
}

main {
  max-width: 1900px;
  margin: 0 auto;
  padding-left: 50px;
  padding-right: 50px;
}

@media (width <= 800px) {
  :is(.spory, .footer), :is(main) {
    padding-left: 0;
    padding-right: 0;
  }
}

.langSwitch {
  text-align: center;
  display: block;
  position: relative;
}

.langSwitch a {
  letter-spacing: 0;
  color: #323549;
  opacity: 1;
  padding: 10px;
  font-weight: 500;
  text-decoration: none;
  display: inline-block;
}

.langSwitch.menu a {
  font-family: Playfair Display, serif;
  font-size: 20px;
  font-weight: 500;
}

@media (width <= 800px) {
  .langSwitch.menu a {
    text-transform: uppercase;
    padding-left: 0;
    padding-right: 0;
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 10px !important;
  }
}

.langSwitch a:hover {
  opacity: 1;
}

@media (width >= 1100px) {
  .langSwitch a.selected:after {
    content: "";
    background-color: #323549;
    width: 100%;
    height: 1px;
    margin-top: 5px;
    display: block;
  }

  .langSwitch a:after {
    content: "";
    -o-transition: width .4s ease;
    background-color: #323549;
    width: 0;
    height: 1px;
    margin-top: 5px;
    transition: width .4s;
    display: block;
  }

  .langSwitch a:hover:after {
    -o-transition: width .4s ease;
    width: 100%;
    transition: width .4s;
  }
}

.anim svg .fadeInLine00 {
  height: 3.5px;
  animation: 2s cubic-bezier(.165, .84, .44, 1) lineOnJedna;
}

@-webkit-keyframes lineOnJedna {
  0% {
    fill-opacity: 0;
    width: 0;
  }

  80% {
  }

  100% {
    fill-opacity: 1;
    width: 645px;
  }
}

@keyframes lineOnJedna {
  0% {
    fill-opacity: 0;
    width: 0;
  }

  50% {
  }

  100% {
    fill-opacity: 1;
    width: 645px;
  }
}

.homeslide.loaded {
  transition: all 1.8s cubic-bezier(.165, .84, .44, 1) .5s;
}

.anim svg .fadeInLine02 {
  animation: 2s cubic-bezier(.165, .84, .44, 1) lineOnDva;
}

@-webkit-keyframes lineOnDva {
  0% {
    fill-opacity: 0;
    width: 0;
  }

  80% {
  }

  100% {
    fill-opacity: 1;
    width: 232px;
  }
}

@keyframes lineOnDva {
  0% {
    fill-opacity: 0;
    width: 0;
  }

  50% {
  }

  100% {
    fill-opacity: 1;
    width: 232px;
  }
}

.fadeInLogo {
  opacity: 0;
}

.logoJP {
  fill: #323549;
}

.anim svg .fadeInLogo {
  opacity: 1;
  transition: all 3.6s cubic-bezier(.165, .84, .44, 1);
}

.anim svg .fadeInLine01 {
  animation: 2s cubic-bezier(.165, .84, .44, 1) lineOnTri;
}

@-webkit-keyframes lineOnTri {
  0% {
    fill-opacity: 0;
    width: 0;
  }

  80% {
  }

  100% {
    fill-opacity: 1;
    width: 886px;
  }
}

@keyframes lineOnTri {
  0% {
    fill-opacity: 0;
    width: 0;
  }

  50% {
  }

  100% {
    fill-opacity: 1;
    width: 886px;
  }
}

.jpFooter {
  text-align: center;
  padding-top: 50px;
}

.jpFooter svg {
  width: 350px;
}

.jpFooter img {
  width: 80px;
}

.footerHolder {
  padding: 20px;
}

.nameHolder {
  padding-top: 50px;
  padding-left: 50px;
}

.left, .middle, .right {
  width: 100%;
}

.right {
  text-align: right;
}

.middle {
  text-align: center;
}

a.odkaz {
  text-transform: uppercase;
  color: #323549;
  border: 1px solid #323549;
  border-radius: 50vw;
  padding: 10px 25px;
  font-family: Montserrat, sans-serif;
  text-decoration: none;
  display: inline-block;
  font-weight: 600 !important;
}

.bio {
  width: 300px;
  padding-top: 12px;
  padding-left: 0;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 200;
  line-height: 20px;
  display: block;
}

.bio strong {
  font-weight: 300;
}

.titul {
  letter-spacing: 3px;
  padding-left: 3px;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 400;
  display: inline;
}

.jmeno {
  margin-top: -20px;
  padding-left: 120px;
  display: block;
}

.prijmeni {
  margin-top: -20px;
  display: block;
}

.jmeno, .prijmeni {
  color: #323549;
  font-family: Playfair Display, serif;
  font-size: 85px;
  font-weight: 700;
}

.lineFooter {
  background-color: #323549;
  width: 100%;
  height: 0;
  margin-top: 30px;
}

.line1 {
  color: #323549;
  font-family: Playfair Display, serif;
  font-size: 20px;
  display: block;
}

.line1 strong {
  font-size: 27px;
  font-weight: 600;
}

.line2 {
  color: #323549;
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  font-weight: 200;
  display: block;
}

.line3, .line4 {
  color: #323549;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 500;
  display: block;
}

.homeslide {
  width: 100%;
  height: 100vh;
  left: 0;
}

nav {
  z-index: 99999;
  width: 100%;
  transition: transform .8s ease-in-out;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

nav.hidden {
  transform: translateY(-100%);
}

.navWrap {
  border-radius: 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 1900px;
  margin: 0 auto;
  padding: 25px;
  display: flex;
  position: relative;
}

#map {
  mix-blend-mode: multiply;
  border-radius: 40px 40px 0 0;
  outline: 0;
  height: 50vh;
  overflow: hidden;
}

#map * {
  outline: 0;
}

.shadow {
  z-index: -1;
  mix-blend-mode: darken;
  border-radius: 20px;
  width: 100%;
  max-width: 1900px;
  margin: 0 auto;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0 1px #11111a1a, 0 8px 24px #11111a1a, 0 16px 48px #11111a1a;
}

.navWrap .langSwitch {
  flex-direction: row;
  justify-content: space-between;
  column-gap: 20px;
  width: auto;
  display: flex;
}

@media (width <= 800px) {
  .navWrap .langSwitch {
    width: auto;
    padding: 0;
  }

  [hideMobile] {
    display: none;
  }

  [hideDesktop] {
    display: block;
  }
}

.homeslide.loaded {
  -o-transition: all .4s ease;
  padding-top: 70px;
  padding-bottom: 70px;
  transition: all .4s;
}

.homeSlide {
  padding: 0;
}

@media (width <= 800px) {
  .homeslide {
    width: 100vw;
  }

  .homeIntro {
    width: 100vw !important;
  }
}

.footer {
  padding-top: 70px;
}

.spory h2 {
  text-align: center;
  padding-bottom: 50px;
}

.spory {
  margin-top: 60px;
}

.sporyInner {
  border-radius: 40px;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: auto;
  margin-top: 60px;
  padding: 100px;
  display: flex;
  box-shadow: inset 0 30px 60px -12px #eecec340, inset 0 18px 36px -18px #0000004d;
}

@media (width <= 1200px) {
  .sporyInner {
    padding: 3px;
  }
}

.innerHolder {
  width: 100%;
  height: 100%;
  position: relative;
}

.pravniSdeleni {
  text-align: right;
}

.col33.line {
  padding-left: 0;
  padding-right: 30px;
}

.linka {
  width: 100%;
  height: 2px;
}

.pravniSdeleniP {
  text-align: left;
  padding-bottom: 40px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.pravniSdeleniP a {
  font-weight: 600;
}

.innnerHolder {
  width: 100%;
  height: 100%;
}

.rowFlex {
  flex-direction: row;
  place-content: stretch space-between;
  column-gap: 40px;
  padding: 50px;
  display: flex;
}

.rowFlex > :first-of-type {
  flex-basis: 33%;
}

.rowFlex > :nth-of-type(2) {
  flex-direction: column;
  flex-basis: 50%;
  padding-left: 40px;
  display: flex;
  position: relative;
}

.rowFlex > :nth-of-type(2):before {
  content: "";
  background-color: #9a7f5b;
  width: 1px;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

@media (width <= 800px) {
  .rowFlex > :first-of-type h2 {
    width: -moz-fit-content;
    width: fit-content;
  }

  .rowFlex {
    flex-direction: column;
    padding: 20px;
    display: flex;
  }

  .rowFlex > :nth-of-type(2) {
    flex-basis: 1px;
    padding-left: 0;
    position: relative;
  }

  .rowFlex > :nth-of-type(2):before {
    display: none;
  }

  .sporyInner {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.headlineHolder {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
}

h2.servicesH2 {
  color: #323549;
  float: left;
  font-family: Playfair Display, serif;
  font-size: 65px;
  font-weight: 700;
  line-height: 1.1;
  display: block;
}

@media (width <= 800px) {
  .headlineHolder {
    flex-direction: column;
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 5px;
    display: flex;
  }
}

h3 {
  padding-right: 15%;
  font-family: Playfair Display, serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 1;
}

.row {
  padding-top: 80px;
}

.serviceItem {
  float: left;
  width: 33%;
  height: 280px;
  margin-bottom: 10px;
  padding-bottom: 30px;
  padding-left: 80px;
  padding-right: 30px;
}

.serviceItem:before {
  content: "";
  background-color: #323549;
  width: 50px;
  height: 2px;
  display: block;
  position: absolute;
  transform: translateX(-80px)translateY(18px);
}

.serviceItem p {
  color: #323549;
  padding-top: 20px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.cleared {
  clear: both;
}

.subHeadline {
  color: #323549;
  letter-spacing: 5px;
  text-transform: uppercase;
  padding-top: 58px;
  padding-left: 30px;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
}

.services {
  width: 100%;
  height: auto;
  padding: 70px 20px;
}

.jp {
  background-image: url("jp.284c8399.jpg");
  background-position: 100% 0;
  background-size: cover;
  width: 100%;
  height: 100%;
  box-shadow: -4px 3px 132px -7px #00000045;
}

.homeIntro {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.homeIntro > div {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  display: flex;
}

.logoHolder {
  mix-blend-mode: darken;
  width: 40vw;
  max-width: 700px;
}

.screen {
  background-image: linear-gradient(15deg, #faf5e8 0%, #fff 100%);
  border-radius: 0 0 30px 30px;
  width: 100vw;
  height: 100svh;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1);
}

@media (height >= 970px) and (height <= 5000px) {
  .services {
    height: auto;
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media (width >= 1600px) and (width <= 5000px) {
  .services, .aboutSlide {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .spory {
    margin: 0 auto;
  }
}

@media (width >= 320px) and (width <= 1024px) {
  .homeslide {
    height: 100svh !important;
  }

  .servicesH2 {
    text-align: center;
    width: 100%;
    display: block;
  }

  .homeslide.loaded {
    padding: 20px;
    transition: all 1.8s cubic-bezier(.165, .84, .44, 1) .5s;
  }

  .hideDesktop {
    display: block;
  }

  .jmeno, .prijmeni {
    color: #323549;
    font-family: Playfair Display, serif;
    font-size: 45px;
    font-weight: 700;
  }

  .titul {
    letter-spacing: 3px;
    padding-bottom: 10px;
    padding-left: 0;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 500;
  }

  .jmeno {
    margin-top: -20px;
    padding-left: 65px;
    display: inline-block;
  }

  .prijmeni {
    margin-top: -15px;
    display: block;
  }

  .bio {
    width: 100%;
    padding-top: 20px;
    padding-left: 0;
    font-family: Montserrat, sans-serif;
    font-size: 11px;
    font-weight: 300;
    line-height: 20px;
    display: block;
  }

  .nameHolder {
    padding: 50px 50px 80px;
  }

  .bio {
    width: 100%;
  }

  .services {
    padding-top: 80px;
  }

  .services .innerContent {
    padding: 5px;
  }

  h2.servicesH2 {
    text-align: center;
    display: block;
  }

  .subHeadline {
    letter-spacing: 3px;
    text-align: center;
    padding-top: 20px;
    padding-left: 0;
    font-size: 12px;
  }

  .serviceItem {
    float: none;
    width: 100%;
    height: auto;
    margin-bottom: 40px;
    padding-bottom: 0;
  }

  .hideMobile {
    display: none;
  }

  .jpFooter svg {
    width: 250px;
  }

  .innnerHolder {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .col20 {
    height: 50px;
  }

  .col20, .col30, .col50 {
    float: none;
    height: auto;
    padding-bottom: 15px;
    position: relative;
    transform: translateY(0%);
  }

  .col50 {
    padding-left: 0;
  }

  .col20 h3, .col33 p, .col50 p {
    transform: translateY(0%);
  }

  .pravniSdeleni {
    text-wrap: nowrap;
    width: max-content;
    display: block;
    text-align: left !important;
  }

  .pravniSdeleniP {
    padding-top: 20px !important;
  }

  .aboutSlide {
    height: 50vh;
    padding-bottom: 50px;
  }

  .spory {
    width: 100%;
  }

  .sporyInner {
    width: 100%;
    height: auto;
  }

  .columnInnerHolder {
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
    overflow: hidden;
  }

  canvas {
    width: 100%;
    display: block;
    position: absolute;
    inset: 0;
  }

  .col20, .col30, .col50, .footer {
    width: 100%;
  }

  .left, .middle, .right {
    text-align: center;
    width: 100%;
    padding-bottom: 30px;
  }

  .logoHolder {
    width: 80%;
  }
}

.aboutSwiper {
  padding-top: 50px;
}

@media (width >= 720px) and (width <= 1199px) {
  .services, .aboutSwiper {
    padding: 50px;
  }
}

@media (width >= 320px) and (width <= 720px) {
  .aboutSlide {
    min-height: 380px;
    height: 320px !important;
  }
}

@media (width >= 720px) and (width <= 1000px) {
  .services {
    padding: 50px;
  }

  .serviceItem:before {
    width: 20px;
    transform: translateX(-40px)translateY(18px);
  }

  .serviceItem {
    float: left;
    width: 50%;
    margin-bottom: 40px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (width >= 720px) and (width <= 1024px) {
  .homeslide {
    padding: 0;
  }

  .aboutSlide {
    height: 450px;
    min-height: 450px;
  }

  .homeslide.loaded {
    padding: 50px;
  }
}

.dotsAnim {
  width: 10px;
  margin: 0 auto;
}

.dotsAnim.jumpTop {
  transform: translateY(-60px);
}

@media (width <= 800px) {
  .dotsAnim.jumpTop {
    padding-top: 20px;
    padding-bottom: 20px;
    transform: translateY(0);
  }
}

.dot01 {
  opacity: .8;
}

.dot02 {
  animation: 5s ease-in-out infinite opat;
}

.dot03 {
  animation: 3s ease-in-out infinite opat;
}

.dot04 {
  animation: 2s ease-in-out infinite opat;
}

@keyframes opat {
  0% {
    fill-opacity: .2;
  }

  50% {
    fill-opacity: .8;
  }

  100% {
    fill-opacity: .2;
  }
}

:is(h3, h2, h1) {
  color: #323549;
}

.hideDesktop {
  display: none !important;
}

.hideMobile {
  display: block !important;
}

@media (width <= 800px) {
  .hideDesktop {
    display: block !important;
  }

  .hideMobile {
    display: none !important;
  }
}

body {
  max-width: 100vw;
  height: 100%;
  overflow-x: hidden;
  overscroll-behavior-y: none !important;
}

html {
  overscroll-behavior-y: none !important;
}

.annonce {
  border-top: 1px solid #9a7f5b;
  border-bottom: 1px solid #9a7f5b;
  margin-top: 15vh;
  margin-bottom: 15vh;
}

.annonce.bono {
  border: 0;
  margin-top: 50px;
  margin-bottom: 50px;
}

.annonce.bono .insideAnnonce, .insideAnnonce {
  text-align: center;
  border-radius: 60px;
  flex-direction: column;
  align-items: center;
  row-gap: 50px;
  width: 80%;
  margin: 0 auto;
  padding: 50px;
  display: flex;
}

.insideAnnonce h2 {
  font-size: 55px;
  font-weight: 700;
  line-height: 1;
}

.insideAnnonce p {
  line-height: 1.5;
}

* {
  text-wrap: balance;
}

.spacer15 {
  height: 15vh;
}

.insideAnnonce .subHeadline {
  color: #323549;
  letter-spacing: 5px;
  text-transform: uppercase;
  text-align: center;
  padding-top: 20px;
  padding-left: 0;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 700;
  display: inline-block;
}

.occup {
  color: #323549;
  font-family: Montserrat, sans-serif !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

a.line3 {
  color: #323549;
  text-decoration: none !important;
}

@media (width <= 800px) {
  .insideAnnonce {
    width: 100%;
  }

  .insideAnnonce h2 {
    font-size: 45px;
    font-weight: 700;
  }

  .insideAnnonce .subHeadline {
    line-height: 1.5;
  }

  .spacer15 {
    height: 5vh;
  }

  main {
    max-width: 100vw;
    overflow-x: hidden;
  }

  .occup {
    font-size: 14px !important;
    line-height: 20px !important;
  }

  .serviceItem p {
    font-size: 14px !important;
  }
}

.sipka {
  height: 80px;
}

.playfaair {
  color: #323549;
  padding-bottom: 10px;
  font-weight: 600;
  display: block;
}

.blue {
  color: #323549;
}

.swiper-slide .who {
  color: #323549;
  padding-top: 15px;
  padding-bottom: 20px;
  font-size: 16px;
  font-family: Playfair Display, serif !important;
}

.content__text {
  -webkit-hyphens: auto;
  hyphens: auto;
  text-align: center;
  letter-spacing: 0;
  color: #323549;
  width: 100vw;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
}

@media (width <= 800px) {
  .content__text {
    font-size: 14px;
  }
}

@media (width <= 720px) {
  .content__text {
    font-size: 14px;
  }
}

.js .content__text {
  opacity: 0;
  pointer-events: none;
  grid-area: content;
}

.js .content__text--current {
  opacity: 1;
  pointer-events: auto;
}

.hideMobileText {
  display: inline-block;
}

@media (width <= 800px) {
  .hideMobileText {
    display: none;
  }

  .navWrap .langSwitch:first-of-type, .navWrap .langSwitch:nth-of-type(2) {
    width: auto;
  }

  .navWrap {
    flex-direction: row;
    column-gap: 10px;
    padding: 15px;
  }

  .navWrap .langSwitch:nth-of-type(2) {
    column-gap: 7px;
  }

  .navWrap .langSwitch:nth-of-type(2) a {
    font-weight: 400;
  }

  .langSwitch.menu a {
    text-transform: uppercase;
    padding-left: 0;
    padding-right: 0;
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 10px !important;
  }
}

.rowServices {
  flex-flow: wrap;
  row-gap: 19px;
  display: flex;
}

.slide-image img {
  object-fit: cover;
  width: 100%;
}

.slide-image {
  border-radius: 0 60px 0 0;
  overflow: hidden;
}



.splitting .word, .splitting .char {
  display: inline-block;
}

.splitting .char {
  position: relative;
}

.splitting .char:before, .splitting .char:after {
  content: attr(data-char);
  visibility: hidden;
  -webkit-user-select: none;
  user-select: none;
  transition: inherit;
  position: absolute;
  top: 0;
  left: 0;
}

.splitting {
  --word-center: calc((var(--word-total)  - 1) / 2);
  --char-center: calc((var(--char-total)  - 1) / 2);
  --line-center: calc((var(--line-total)  - 1) / 2);
}

.splitting .word {
  --word-percent: calc(var(--word-index) / var(--word-total));
  --line-percent: calc(var(--line-index) / var(--line-total));
}

.splitting .char {
  --char-percent: calc(var(--char-index) / var(--char-total));
  --char-offset: calc(var(--char-index)  - var(--char-center));
  --distance: calc((var(--char-offset) * var(--char-offset)) / var(--char-center));
  --distance-sine: calc(var(--char-offset) / var(--char-center));
  --distance-percent: calc((var(--distance) / var(--char-center)));
}

.splitting.cells img {
  width: 100%;
  display: block;
}

@supports (display: grid ) {
  .splitting.cells {
    visibility: hidden;
    background-size: cover;
    position: relative;
    overflow: hidden;
  }

  .splitting .cell-grid {
    background: inherit;
    grid-template: repeat(var(--row-total), 1fr) / repeat(var(--col-total), 1fr);
    width: 100%;
    height: 100%;
    display: grid;
    position: absolute;
    top: 0;
    left: 0;
  }

  .splitting .cell {
    background: inherit;
    position: relative;
    overflow: hidden;
  }

  .splitting .cell-inner {
    background: inherit;
    visibility: visible;
    width: calc(100% * var(--col-total));
    height: calc(100% * var(--row-total));
    left: calc(-100% * var(--col-index));
    top: calc(-100% * var(--row-index));
    position: absolute;
  }

  .splitting .cell {
    --center-x: calc((var(--col-total)  - 1) / 2);
    --center-y: calc((var(--row-total)  - 1) / 2);
    --offset-x: calc(var(--col-index)  - var(--center-x));
    --offset-y: calc(var(--row-index)  - var(--center-y));
    --distance-x: calc((var(--offset-x) * var(--offset-x)) / var(--center-x));
    --distance-y: calc((var(--offset-y) * var(--offset-y)) / var(--center-y));
  }
}

/*# sourceMappingURL=index.f1870c21.css.map */
