.hideMobileText {
  display: inline-block;
}
@media (max-width: 800px) {
  .hideMobileText {
    display: none;
  }
  .navWrap .langSwitch:nth-of-type(1) {
    width: auto;
  }
  .navWrap .langSwitch:nth-of-type(2) {
    width: auto;
  }
  .navWrap {
    padding: 15px;
    flex-direction: row;
    column-gap: 10px;
  }
  .navWrap .langSwitch:nth-of-type(2) {
    column-gap: 7px;
  }

  .navWrap .langSwitch:nth-of-type(2) a {
    font-weight: 400;
  }

  .langSwitch.menu a {
    font-family: "Montserrat", sans-serif;
    font-size: 10px !important;
    font-weight: 600;
    padding-left: 0;
    padding-right: 0;
    text-transform: uppercase;
  }
}

.rowServices {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 19px;
}

.rowServices > div {
}

.slide-image img {
  object-fit: cover;
  width: 100%;
}

.slide-image {
  border-radius: 0 60px 0px 0px;
  overflow: hidden;
}
